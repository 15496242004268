import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './css/swiper2.css';
import useGoogleSheets from 'use-google-sheets';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

import Cardbg from '../../images/home/card.png';

export default function Testimonials() {
	const { data: testimonials, loading } = useGoogleSheets({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
		sheetsOptions: [{ id: 'Testimonials' }]
	});
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				pagination={{
					clickable: true
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false
				}}
				breakpoints={{
					640: {
						slidesPerView: 1,
						spaceBetween: 20
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 40
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 50
					}
				}}
				modules={[Pagination, Navigation, Autoplay]}
				className='mySwiper'
			>
				{!loading &&
					testimonials[0] &&
					testimonials[0].data.map((testimonial, index) => (
						<SwiperSlide>
							<div className='w-full relative'>
								<img src={Cardbg} alt='' className='z-100 w-full ' />
								<iframe
									src={testimonial['Video URL']}
									title={testimonial.Title}
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
									className='absolute -top-[5%] w-[90%] h-full left-0  border-4 border-[#739b24]'
								></iframe>
							</div>
						</SwiperSlide>
					))}
			</Swiper>
		</>
	);
}
