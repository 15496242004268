import React from 'react';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import CardServicesComponent from '../components/Services/CardServicesComponent';
import { Helmet } from 'react-helmet';

const Services = () => {
	return (
		<div>
			<Helmet>
				<title>Services</title>
			</Helmet>
			<Navbar page={'Services'} />'
			<br />
			<CardServicesComponent />
			<Footer />
		</div>
	);
};

export default Services;
