import React, { useEffect, useState, Fragment } from 'react';
import useGoogleSheets from 'use-google-sheets';

import { DoctorsCard } from './DoctorsCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Mousewheel, Keyboard } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../../Home/css/swiper2.css';

const Doctor = () => {
	const { data: fetchedDocs, loading } = useGoogleSheets({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
		sheetsOptions: [{ id: 'Doctors' }]
	});
	const allDoctors = !loading ? fetchedDocs[0] && fetchedDocs[0].data : [];
	const [filteredDoctors, setFilteredDoctors] = useState([]);
	const [specializationDoctors, setSpecializationDoctors] = useState('All');
	useEffect(() => {
		setFilteredDoctors(!loading ? fetchedDocs[0] && fetchedDocs[0].data : []);
	}, [loading, fetchedDocs]);

	return (
		<>
			{/* <Filter
				allDoctors={allDoctors}
				filteredDoctors={filteredDoctors}
				setFilteredDoctors={setFilteredDoctors}
				specializationDoctors={specializationDoctors}
				setSpecializationDoctors={setSpecializationDoctors}
			/> */}

			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				
				// autoplay={{
				// 	delay: 8000,
				// 	disableOnInteraction: false
				// }}
				breakpoints={{
					640: {
						slidesPerView: 1,
						spaceBetween: 20
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 40
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 10
					}
				}}
				cssMode={true}
				navigation={true}
				pagination={{
					clickable:true
				}}
				mousewheel={true}
				autoplay={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className='mySwiper bg-transparent'
			>
				{filteredDoctors && filteredDoctors.length > 0 ? (
					filteredDoctors.map((doctor, index) => (
						<SwiperSlide>
							<DoctorsCard doctor={doctor} />
						</SwiperSlide>
					))
				) : (
					<div>No doctors found</div>
				)}
			</Swiper>
		</>
	);
};

export default Doctor;
