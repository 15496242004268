import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import herobg from "../images/home/Group2.png";
import Slider from "../components/Home/swiper";
import Testimonials from "../components/Home/testimonials";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Timeline from "../components/Home/timeline";
import { Link } from "react-router-dom";
import EventsMain from "../components/Home/EventsMain";
import titleLogo from "../images/Andhaadhi-Logo.png";
import useGoogleSheets from "use-google-sheets";
const Home = () => {
  const { data: achievement, loading } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "Achievement" }],
});
  return (
    <div className="">
      <Helmet>
        <title>Home</title>
      </Helmet>

			<Navbar className='z-50' page={'Home'} />
			{/* hero */}

			<section className='h-[260px] sm:h-[350px] md:h-screen flex flex-col items-center justify-center z-0 relative'>
				<img
					src={herobg}
					alt=''
					className='h-3/6 w-full md:h-4/6 absolute top-0 -z-50'
				/>
				<div className='relative top-0 left-1/2 -translate-x-1/2 lg:mb-3 w-full flex justify-center items-center'>
					{/* <h1 className="text-white font-bold  text-xl sm:text-4xl">
                        Andhaadhi Rehab
                    </h1> */}
					<img
						src={titleLogo}
						alt=''
						className='w-[70px] md:w-[160px] relative top-0 z-[100]'
					/>
					{/* <p className='text-white font-medium sm:text-xl text-center'>Andhaadhi Rehab</p> */}
				</div>
				<div className='max-w-[400px] sm:max-w-[none] w-[90%] sm:w-3/6 h-3/4  z-10 border-r-8 border-t-8 border-white shadow-lg'>
					<Slider className=''></Slider>
				</div>
			</section>
			<section className='px-10 md:px-44 py-10 w-full'>
				<h1 className='text-3xl text-[#739b24] '>Welcome to Andhaadhi Rehab</h1>
				<br />
				<div className=' flex flex-col md:flex-row justify-between w-full'>
					<div>
						<p className='text-justify md:mr-32'>
							One of India’s most trusted and leading teletherapy hub for Speech
							therapy, Occupational therapy and special education services. Get
							expert opinion for all your queries related to Child development,
							Speech and swallowing difficulties from the comfort of your home !
						</p>
						<div className=' mx-auto sm:mt-10'>
							{/* <h1 className="font-bold text-2xl md:text-3xl text-[#739b24] text-left mb-3"></h1> */}
							<div className='wrapper'>
								<HashLink smooth className='cta' to='/about/#team'>
									<span className='text-lg'>Check our Team</span>
									<span>
										<svg
											className='w-[40px] h-[20px]'
											viewBox='0 0 66 43'
											version='1.1'
											xmlns='http://www.w3.org/2000/svg'
											xmlnsXlink='http://www.w3.org/1999/xlink'
										>
											<g
												id='arrow'
												stroke='none'
												strokeWidth={1}
												fill='none'
												fillRule='evenodd'
											>
												<path
													className='one'
													d='M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z'
													fill='#FFFFFF'
												/>
												<path
													className='two'
													d='M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z'
													fill='#FFFFFF'
												/>
												<path
													className='three'
													d='M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z'
													fill='#FFFFFF'
												/>
											</g>
										</svg>
									</span>
								</HashLink>
							</div>
						</div>
					</div>
					<p className='opacity-50 font-semibold text-justify'>
						We take pride in ourselves, for providing exceptional services for
						all our clients since 2020. We merge scientific data and modern
						technology to support families of Individuals with Speech
						difficulties. Our team of experts are well qualified and undergo
						exclusive intensive training for handling Teletherapy sessions
						effectively
						<br />
						<br />
						We are Neurodiversity affirming Practice. We respect Autistic styles
						of learning and support them accordingly. At Andhaadhi Rehab we
						strive everyday to level up our clinical skills, Promote advocacy,
						Increase acceptance and awareness related to Developmental Language
						Disorder, Autism Spectrum and other Neurodivergent conditions
						<br />
						<br />
						We specialize in Early identification and Early intervention of
						Children with speech delay, Autism. We also specialize in Parent
						coaching. Through our effective parent coaching via our 1-1
						sessions, we have recorded some tremendous improvement in our
						clients. Why ask us? When you can hear it from them.
						<br />
						<br />
						<Link to='/testimonials' className='text-cyan-600'>
							Tap to watch our Testimonials
						</Link>
					</p>
				</div>
			</section>

			{/* <section>
            <h1 className="text-4xl font-bold p-3">Achievements</h1>
            </section> */}

			{/* timeline */}
			{/* <Timeline /> */}

			{/* Events */}

      
      {/* {JSON.stringify(achievement  && achievement)} */}
    

      <EventsMain />

			{/* achievements */}

      <div className="relative  h-[350px] md:h-[500px] mt-3 md:mt-16 flex items-center justify-center">
        <div
          className="w-4/6 h-[276px] md:h-[426px] inset-x-2/4 -translate-x-1/2 absolute bg-[#739B24] "
          style={{
            borderRadius: "40% 40% 40% 40% / 7% 7% 7% 7%",
            background: "#AFE198",
          }}
        ></div>
        <div
          className="w-full h-[250px] md:h-[400px] flex justify-evenly items-center bg-[#AFE198] border-8 border-white relative overflow-hidden"
          style={{
            borderRadius: "45% 45% 45% 45% / 15% 15% 15% 15% ",
            background: "#AFE198",
          }}
        >
          <img
            src={require("../images/home/therapy.png")}
            className="absolute w-full  opacity-50"
            alt=""
          />
          <div className="z-10 flex flex-col items-center">
            <h1 className=" text-black text-2xl md:text-4xl  font-bold mb-2">
              {achievement  && achievement[0] && achievement[0].data[0].count}
            </h1>
            <h1 className=" text-black text-1xl md:text-2xl font-normal">
            {achievement  && achievement[0] && achievement[0].data[0].name}
            </h1>
          </div>
          <div className="z-10 flex flex-col items-center">
            <h1 className=" text-black text-2xl md:text-4xl font-bold mb-2">
            {achievement  && achievement[0] && achievement[0].data[1].count}+
            </h1>
            <h1 className=" text-black text-1xl md:text-2xl font-normal">
            {achievement  && achievement[0] && achievement[0].data[1].name}
            </h1>
          </div>
          <div className="z-10 flex flex-col items-center">
            <h1 className=" text-black text-2xl md:text-4xl font-bold mb-2">
            {achievement  && achievement[0] && achievement[0].data[2].count}
            </h1>
            <h1 className=" text-black text-1xl md:text-2xl font-normal">
            {achievement  && achievement[0] && achievement[0].data[2].name}
            </h1>
          </div>
        </div>
      </div>

			{/* <div className='w-3/4 mx-auto sm:mt-10'>
				<div className='wrapper'>
					<Link className='cta' to='/about/#doc'>
						<span>Check our Team</span>
						<span>
							<svg
								width='66px'
								height='43px'
								viewBox='0 0 66 43'
								version='1.1'
								xmlns='http://www.w3.org/2000/svg'
								xmlnsXlink='http://www.w3.org/1999/xlink'
							>
								<g
									id='arrow'
									stroke='none'
									strokeWidth={1}
									fill='none'
									fillRule='evenodd'
								>
									<path
										className='one'
										d='M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z'
										fill='#FFFFFF'
									/>
									<path
										className='two'
										d='M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z'
										fill='#FFFFFF'
									/>
									<path
										className='three'
										d='M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z'
										fill='#FFFFFF'
									/>
								</g>
							</svg>
						</span>
					</Link>
				</div> */}

			{/* <div className="">
          <a
            href="/about#doctors"
            className="max-w-lg text-xl font-bold leading-normal text-center text-[#1b67ca]"
          >
            {" "}
            
          </a>
        </div> */}
			{/* </div> */}
			{/* testimonials */}

			<div className='w-3/4 mx-auto sm:mt-10'>
				<h1 className='font-bold text-2xl md:text-3xl text-[#739b24] text-left mb-3'>
					<span className='underline underline-offset-8'>Testimonials</span>
				</h1>
				<div className='w-full h-[350px] sm:h-[400px]'>
					<Testimonials />
				</div>
			</div>

			{/* footer */}

			<Footer />

			{/* {user && user.email} */}

			{/* <button onClick={handleLogOut}>Log Out</button> */}
		</div>
	);
};

export default Home;
