import React, { Fragment } from "react";
// import Achievements from "../components/About/Achievements";
import Doctors from "../components/About/Doctors";
import FirstSection from "../components/About/FirstSection";
// import PatientStats from "../components/About/PatientStats";
import Navbar from "../components/navbar/Navbar";
import "swiper/css/bundle";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";
import FAQ from "../components/About/FAQ.component";
import useGoogleSheets from "use-google-sheets";
// import Navbar from "../"

const About = () => {
    const { data: fetchedAbout, loading } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsOptions: [{ id: "About" }],
    });
    return (
        <Fragment>
            <Helmet>
                <title>About</title>
            </Helmet>
            <section className="bg-[#fefefe]">
                <Navbar page={"About"} />
                <FirstSection
                    fetchedAbout={fetchedAbout[0] && fetchedAbout[0].data}
                />

                {/* <Achievements /> */}
                <br />
                <div className="relative px-10 flex justify-center flex-col items-center w-full">
                    <h1 className="text-4xl font-bold p-3">About Founder</h1>
                    <p className="lg:w-[80%] text-[1.1rem] py-3 sm:w-full text-justify">
                        Vaishnavi is a Speech therapist with a Masters in Speech
                        Language Pathology. She also got her Certified from the
                        Hanen Organisation, Canada. This allows her to work
                        along with educators and train them on language
                        development, social skills and literacy development. She
                        believes in naturalistic and family centered approaches
                        for development and Speech and Language skills. Speech
                        and Language disorders are so common, speech delays are
                        on a surge especially post covid but finding appropriate
                        services are hard. She believes that via teletherapy the
                        gap can be bridged and we can also make culturally
                        relevant therapy accessible across the globe.
                        <br />
                        <br />
                        Building an interactive, responsive and stimulating
                        environment for child development is vital, and She
                        believes with structured guidance and training for
                        Parents and teachers / early childhood educators we can
                        make a lot of difference in the lives of our children !
                        In the past 3 years She has worked with 200+ families,
                        has educated 100+ parents via workshops and educated
                        thousands of people via active online content creation
                        in Instagram and Youtube. She hopes to educate and
                        empower more parents. She crosses paths within her
                        professional practice, with evidence based information.
                    </p>
                </div>
                <br />
                <FAQ />
                <br />
                <div id="team">
                <Doctors  />
                </div>
              
                <Footer  />
            </section>
           
        </Fragment>
    );
};

export default About;
