import React from "react";
import aboutImage from "../../images/about/AboutSection.png";
import pic from "../../images/about/pic.JPG";

import image from "../../images/home/slider2.JPG";

const FirstSection = ({ fetchedAbout }) => {
    return (
        <>
            <section className="relative h-auto lg:mb-5 w-full flex flex-col items-center">
                <img
                    src={aboutImage}
                    alt=""
                    className="w-full h-4/6 absolute top-0 lg:block hidden"
                />
                <div className="relative flex justify-center items-center w-full h-auto lg:mt-24 mt-10">
                    <div className="relative w-64 h-64 rounded-full bg-[#cac7cb]">
                        <img
                            className="relative flex w-full h-full rounded-full flex-col items-center object-contain"
                            // src="https://neuspurt.com/public/storage/avatars/avatar-41.jpg"
                            // src={pic}
                            src="https://res.cloudinary.com/ddscpgg6f/image/upload/v1686626588/bg-transparent_kgpkkq.png"
                            alt=""
                        />
                    </div>
                </div>
                
            </section>
        </>
    );
};

export default FirstSection;
