import React, { useState } from "react";
const CardComponent = ({ name, description, image, link }) => {
    const [popup, setPopup] = useState(false);

    console.log(link);

    var imgDrive =image? image.split("/")[5] : "link"
    return (
        <>
            <div className="relative  w-48  lg:flex my-5 flex flex-col m-3">
                <div className="h-4w-48 lg:h-auto flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
                    <img
                      src={`https://drive.google.com/uc?export=view&id=${imgDrive}`}
                        className="object-cover w-full h-full"
                        alt=""
                        srcset=""
                    />
                </div>
                <button
                    className="bg-transparent hover:bg-blue-800 transition-all bg-blue-500 text-white font-semibold hover:text-white  py-2 px-4 border fover:border-transparent rounded"
                    data-modal-toggle="medium-modal"
                    onClick={() => {
                        setPopup(!popup);
                        // window.open("https://youtu.be/wkTVTKQgWQ4")
                    }}
                >
                    View More
                </button>
            </div>

            {popup && popup ? (
                <div className="fixed w-full h-full bg-[rgba(0,0,0,0.3)] top-0 left-0 flex flex-col justify-center items-center z-50">
                    <p className="relative rounded-lg w-[75%] m-10 bg-white p-5 py-14 text-justify">
                        <h1 className="font-bold text-2xl md:text-2xl text-[#739b24] text-left mb-5">
                            {name}
                        </h1>
                        <img
                            className="absolute top-0 right-0 p-2 cursor-pointer"
                            src="https://img.icons8.com/sf-ultralight/35/null/xbox-x.png"
                            onClick={() => setPopup(!popup)}
                            alt=""
                        />
                        <p>{description}</p>

                        <a rel="noreferrer" className="underline" target="_blank" href={link}>
                            Youtube link
                        </a>
                    </p>
                </div>
            ) : null}
        </>
    );
};

export default CardComponent;
