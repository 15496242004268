import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import Doctor from "./Pages/Doctor";
import About from "./Pages/About";
import Testimonials from "./Pages/Testimonial";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./index.css";
import Contact from "./Pages/Contact";
import Events from "./Pages/Events";
import Services from "./Pages/Services";

function App() {
    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/doctor/:name" element={<Doctor />} />
                    <Route path="/testimonials" element={<Testimonials />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
