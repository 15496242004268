import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import CheckMark from '../../images/doctor/checkmark.svg';
import emailjs from '@emailjs/browser';
import useGoogleSheets from 'use-google-sheets';

const CheckUpType = ({
	selectedCheckUp,
	setSelectedCheckUp,
	doctorDetails
}) => {
	const [isSlotBookOpen, setIsSlotBookOpen] = useState(false);
	const [details, setDetails] = useState([]);
	const [data, setdata] = useState([]);

	const form = useRef();

	const { data: checkuptype, loading } = useGoogleSheets({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
		sheetsOptions: [{ id: 'Checkup Type' }]
	});
	useEffect(() => {
		setdata(
			// eslint-disable-next-line
			!loading ? checkuptype[0].data : {}
		);
	}, [loading, checkuptype]);

	const sendEmail = (e) => {
		e.preventDefault();
		axios
			.post(`https://andhaadhirehab.in/api/bookslot`, {
				email: form.current[0].value,
				name: form.current[1].value,
				phone: form.current[2].value,
				doctorDetails,
				slot: isSlotBookOpen
			})
			.then((res) => {
				if (res.data === 'ok') {
					alert('Your request has been raised. Please check your mailbox.');
					setIsSlotBookOpen(false);
				}
			})
			.catch((err) => {
				alert('Some error occured. Try contacting us later.');
				setIsSlotBookOpen(false);
			});
	};
	if (!doctorDetails) return;
	return (
		<>
			<div className='my-10 w-full flex items-center justify-center relative'>
				{/* {JSON.stringify(data && data)} */}
				<div className='w-3/4 shadow-lg rounded'>
					<div className='bg-gradient-to-r from-[#246097] to-[#9D40AD] text-white text-center p-2 rounded-t-lg'>
						Services Provided
					</div>
					<div className='flex flex-col md:flex-row overflow-scroll scrollbar-hide'>
						{doctorDetails['Service provided'] &&
							data.length > 0 &&
							data.map((checkUp, index) => {
								if (
									!doctorDetails['Service provided']
										.split(',')
										.map((sp) => sp.trim().toLowerCase())
										.includes(checkUp.name.toLowerCase())
								)
									return null;
								return (
									<SelectCheckUp
										key={index}
										checkUp={checkUp}
										selectedCheckUp={selectedCheckUp}
										setSelectedCheckUp={setSelectedCheckUp}
									/>
								);
							})}
					</div>
				</div>
			</div>
			{selectedCheckUp && (
				<div className='h-fit w-full flex items-left md:text-center justify-center relative mt-5'>
					{data &&
						data.map(
							({  name, description, duration }) =>
								name.toLowerCase() === selectedCheckUp.toLowerCase() && (
									<div className='w-3/4 shadow-lg rounded p-8 mb-5' key={name}>
										<h1 className='font-medium text-xl mb-3'>{name}</h1>
										{duration && (
											<p className='my-5 font-semibold'>Duration: {duration}</p>
										)}
										<p className='text-justify'>{description}</p>
										<button
											className={`bg-gradient-to-r from-[#246097] to-[#9D40AD] text-white p-2 px-8 my-3 shadow-lg rounded `}
											onClick={() =>
												setIsSlotBookOpen({
													name,
													description,
													duration
												})
											}
										>
											BOOK SLOT
										</button>
									</div>
								)
						)}
				</div>
			)}
			{isSlotBookOpen && (
				// <DateTimePopup
				// 	doctorDetails={doctorDetails}
				// 	selectedDate={selectedDate}
				// 	setSelectedDate={setSelectedDate}
				// 	selectedTime={selectedTime}
				// 	setSelectedTime={setSelectedTime}
				// />
				<div className='bg-gray-800 bg-opacity-50 w-full h-full fixed top-0 z-50 flex items-center justify-center'>
					<div className='bg-white p-10 rounded'>
						<form ref={form} onSubmit={sendEmail} className='flex-col flex'>
							<h1 className='font-semibold ml-1 text-lg'>
								Book your slot now!
							</h1>
							<input
								className='outline-none shadow-lg m-1 p-2 px-5 rounded'
								type='email'
								id='email'
								name='user_email'
								placeholder='Enter your email'
							/>
							<input
								className='outline-none shadow-lg m-1 p-2 px-5 rounded'
								type='text'
								id='name'
								name='name'
								placeholder='Enter your name'
							/>
							<input
								className='outline-none shadow-lg m-1 p-2 px-5 rounded'
								type='text'
								id='phone number'
								name='phone'
								placeholder='Enter your phone number'
							/>
							<button
								className={`bg-gradient-to-r from-[#246097] to-[#9D40AD] text-white p-2 px-8 my-3 shadow-lg rounded `}
								// onClick={sendEmail}
							>
								BOOK SLOT
							</button>
							<button
								className={`bg-gradient-to-r bg-opacity-50 from-[#246097] to-[#9D40AD] text-white p-2 px-8 mt-0 my-3 shadow-lg rounded `}
								onClick={() => setIsSlotBookOpen(null)}
							>
								CANCEL
							</button>
						</form>
					</div>
				</div>
			)}
		</>
	);
};

// const data = [
//     {
//         id: "Speech Therapy",
//         name: "Speech Therapy",
//         duration: "45 minutes",
//         description:
//             "The detailed assessment duration can go upto 1.5 to 2 hours and it will be held through zoom meeting. The detailed assessment comprises of collection of demographic data, complaint or reason for availing the detail assessment, birth history, medical history, rehabilitation history collection, standardized test material assessment, parent-child interaction, reading and writing skills assessment (if applicable), followed by discussion about test findings and counseling. Once the detailed assessment is done, admin will help you fix slots for therapy sessions as per the Clinician's recommendation. Once the payment is done, admin will help you in fixing the slot for detailed assessment. Laptops or tablets and Wi-Fi are compulsory to attend an assessment session. Post detailed assessment, a detailed report comprising of all the information will be sent through mail.",
//     },
//     {
//         id: "OCCUPATIONAL THERAPY",
//         name: "OCCUPATIONAL THERAPY",
//         duration: "2 hours",
//         description:
//             "A detailed Occupational Therapy Assessment will be held on one-on-one basis for upto 2 hours through a zoom meeting. The detailed occupational assessment comprises of occupational profile which contains demographic data, medical history, significance history of childhood, developmental milestone, screen time history, strength and weaknesses of the child, rehabilitation history and previous assessment history. Followed by assessment of gross motor and fine motor skills, visual motor skills, oral motor skills, feeding history, vegetative skills, oral processing skills, sensory perceptual skills, sensory profile (visual tactile auditory, vestibular, proprioceptive), cognitive skills, academy skills, communication and play skills, behavioral issues. At the end of the evaluation short and long term goals dealt in therapy will be discussed by the Clinician. Once the payment is done, admin will help you in fixing the slot for detailed Occupational Therapy assessment. Laptops or tablets and Wi-Fi are compulsory to attend an assessment session. Post detailed assessment, a detailed report comprising of all the information will be sent through mail.",
//     },
//     {
//         id: "SPECIAL EDUCATION",
//         name: "SPECIAL EDUCATION",
//         duration: "1 hour 30 minutes",
//         description:
//             "Parent training Program is a series of 8 sessions, where an Expert Speech therapist coaches a Parent on a 1-1 set up. This is usually scheduled after an evaluation. The Speech therapist will coach some of the most effective and research evidence based Speech and language building strategies, that can be used by the parent hassle freely in an everyday context! We have produced tremendous results in the past after this program, all of our clients found this very effective, and we have observed improved quality of Parent child interaction, increased intent of the child to communicate, and more connection after this program.",
//     },
//     {
//         id: "Parent Training Program",
//         name: "Parent Training Program",
//         duration: "45 minutes",
//         description: `Parent training Program is a series of 8 sessions, where an Expert Speech therapist coaches a Parent on a 1-1 set up. This is usually scheduled after an evaluation. The Speech therapist will coach some of the most effective and research evidence based Speech and language building strategies, that can be used by the parent hassle freely in an everyday context ! We have produced tremendous results in the past after this program, all of our clients found this very effective, and we have observed improved quality of Parent child interaction, increased intent of the child to communicate, and more connection after this program.`,
//     },
//     {
//         id: "Review session",
//         name: "Review session",
//         duration: "",
//         description: `The clinical supervisor will meet the client every month, oversee the current short term goals which have been focussed on in the sessions, review any new concerns / questions from the parents, Provide suggestions and Clarity and what to focus on, in the upcoming sessions, and may provide personalized further recommendations needed.
// 			`,
//     },
//     {
//         id: "Course for Teachers",
//         name: "Course for Teachers",
//         duration: "3 to 6 days",
//         description: `Andhaadhi Rehab provides an Internationally renowned Certification course for Early childhood teachers and Daycare providers. The Teacher Talk course is from The Hanen center, Canada. The program has a high research basis. Participants will be Trained by Ms. Vaishnavi, who is a Hanen certified Speech therapist.

// 			This course will help teachers in identifying children with Speech delays in their classrooms, and equip them with research based strategies to support the children for better Language and literacy development.

// 			All the participants will be receiving a Certificate on completion, and this can be accounted for professional development hours, which can add value to their resume.
// 			`,
//     },
//     {
//         id: "Workshop",
//         name: "Workshop",
//         duration: "3 hours",
//         description: `Andhaadhi Rehab offers “Mazhalai Pechu - Help your kid talk” a Workshop for Parents of children from 0 - 3 years old. This Workshop addresses a range of topics from Different causes of childhood communication disorders, Milestones - Motor, Speech and Language, Hearing & Feeding. Popular Myths and associated facts, Simple and effective Tips which can be implemented during play to stimulate vocabulary development and more !

// 			Participants will have access to the session recording for 2 days ! Lifelong access for Handouts for all the topics covered, and a 1-1 Parent coaching session with Ms. Vaishnavi, within 1 month of the workshop.
// 			`,
//     },
//     {
//         id: "AAC Guidance",
//         name: "AAC Guidance",
//         duration: "45 Minutes",
//         description: `AAC stands for Augmentative and Alternative communication, it simply means using different modalities to communicate other than Speech. To Augment means - “adding” something to a person’s speech and To Alternate means - something other than Speech.

// 			AAC has been gaining a lot of visibility and evidence since the past decade. Therapists at Andhaadhi Rehab are huge advocates of AAC. AAC can be used by anyone with complex communication needs. There are many Types of AAC like - No tech, low tech and High tech.

// 			AAC allows Non speaking Autistic individuals to have a voice of their own, it is so empowering. It's a highly Neurodiversity affirming practice !

// 			In the AAC guidance sessions, we will be able to suggest a suitable AAC device / modality personalized as per the child’s abilities, differences and strengths. We will guide the caregiver on how to design to, use it, implement it step by step and how to transfer the learnt skills across different environments.
// 			`,
//     },
//     {
//         id: "Consultation",
//         name: "Consultation",
//         duration: "30 minutes",
//         description:
//             'The consultation will be held on one on one basis by our professionals (Speech Therapists, Occupational Therapists or Special Educators). The consultation will be done through zoom meetings. During the consultation, the professionals will be clarifying the general queries asked by the clients like, "Is therapy really necessary?" , "What is the current status of the child?" , "What is teletherapy?" , "What happens during an assessment session?" , "Will the client be a good candidate for teletherapy?" and direct them accordingly The consultation will go on for upto 30 minutes. After the consultation, our admin will guide you regarding the further details.Once the payment is done, admin will help you fix the slot for the consultation. The consultation can be attended through laptops, computers, tablets, mobile phones with good internet connection. Important note:Consultation is not a substitute for assessment, it helps in clarifying the initial stage of queries. Appointments cannot be rescheduled once allotted. Refund shall not be provided under any circumstances.',
//     },
// ];

const SelectCheckUp = ({ checkUp, selectedCheckUp, setSelectedCheckUp }) => {
	return (
		<div
			className={`w-full border-y-2 md:border-y-0 md:border-x-2 border-x-0 border-[#5753A0] p-6 text-center flex items-center justify-center flex-col`}
		>
			<h2>{checkUp.name}</h2>
			<h3 className='mt-2'>Time - {checkUp.duration}</h3>
			<button
				className={`bg-gradient-to-r from-[#246097] to-[#9D40AD] text-white text-center p-2 px-8 m-3 rounded-full ${
					selectedCheckUp === checkUp.name ? 'shadow-lg' : 'shadow-md'
				}`}
				onClick={() => setSelectedCheckUp(checkUp.name)}
			>
				{selectedCheckUp === checkUp.name ? (
					<img src={CheckMark} alt='Checkmark' />
				) : (
					'Select'
				)}
			</button>
		</div>
	);
};

export default CheckUpType;
