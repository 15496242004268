import React from 'react';
import useGoogleSheets from 'use-google-sheets';
import Faq from 'react-faq-component';

const FAQ = () => {
	const { data: fetchedFAQs, loading } = useGoogleSheets({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
		sheetsOptions: [{ id: 'FAQ' }]
	});
	return (
		<div className='w-3/4 mx-auto min-h-[400px]'>
			<h1 className='font-bold text-2xl md:text-3xl text-[#739b24] text-left mb-5'>
				<span className='underline underline-offset-8'>FAQ</span>
			</h1>
			<Faq
				data={{
					rows: fetchedFAQs[0] && fetchedFAQs[0].data
				}}
				styles={{
					bgColor: 'transparent',
					titleTextColor: 'black',
					rowTitleColor: 'black'
					// rowContentColor: 'grey',
					// arrowColor: "red",
				}}
				config={{
					animate: true,
					arrowIcon: '⌄',
					tabFocus: true
				}}
			/>
		</div>
	);
};

export default FAQ;
