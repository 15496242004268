import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

import slider1 from "../../images/home/slider1.JPG";
import slider2 from "../../images/home/slider2.JPG";
import slider3 from "../../images/home/slider3.JPG";
import slider4 from "../../images/home/slider4.JPG";
import slider5 from "../../images/home/slider5.jpeg";
import slider6 from "../../images/home/slider6.jpeg";
import slider7 from "../../images/home/slider7.jpeg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./css/swiper.css";

// import required modules
import {Navigation, Pagination, Mousewheel, Keyboard} from "swiper";

export default function Slider() {
	return (
		<>
			<Swiper
				cssMode={true}
				navigation={true}
				pagination={true}
				mousewheel={true}
				autoplay={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className="mySwiper "
			>
				<SwiperSlide>
					<img
						src={slider1}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider2}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider3}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider4}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider5}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider6}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={slider7}
						alt=""
						className="object-contain "
					/>
				</SwiperSlide>
			</Swiper>
		</>
	);
}
