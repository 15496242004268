import React from "react";
import Doctor from "./AboutComponents/Doctor";

const Doctors = () => {
	return (
		<>
			<section className="relative h-auto flex flex-col justify-center items-center w-[80%] mx-auto">
				<div className="relative h-full w-full flex flex-row flex-wrap justify-between text-center items-center px-10">
					{/* <PaginatedItems /> */}
					{/* <h1 className="text-3xl my-10 font-bold">Our doctors</h1> */}
						<h1 className="py-5 font-bold text-2xl md:text-3xl text-[#739b24] text-left mb-5">
							<span className="underline underline-offset-8">
								Our T
							</span>
							eam
						</h1>
					{/* <h1 className="text-4xl font-bold">Filter</h1> */}
				</div>
				<Doctor />
			</section>
		</>
	);
};

export default Doctors;
