import React from 'react';
import { Link } from 'react-router-dom';
import footerimg from '../../images/footer/footer.png';
import logo from '../../images/Andhaadhi-Logo.png';

const Footer = () => {
	return (
		<>
			<footer className='relative  text-center w-full lg:text-left pt-14 bg-[#fefefe]'>
				<img
					src={footerimg}
					alt=''
					className='absolute top-0 left-0 w-full h-full object-cover object-top z-0'
				/>
				<div className='relative container p-6 text-gray-800 z-10'>
					<div className='grid lg:grid-cols-3 gap-4'>
						{/* <div className="mb-6 md:mb-0 w-full h-full bg-black">
              <div className="mb-4"></div>
            </div> */}

						{/* <h1>Hello</h1> */}

						<div className='relative mb-6 md:mb-0 w-full h-full flex flex-col flex-wrap justify-center items-center'>
							<img className='w-36 object-cover' src={logo} alt='' />
						</div>
						<div className='mb-6 md:mb-0 w-full h-full flex flex-col flex-wrap justify-center items-center'>
							<div>
								<h5 className='uppercase font-bold text-xl  mb-2.5 text-gray-800'>
									Links
								</h5>
								<ul className='list-none mb-0 text-xl'>
									<li>
										<Link to='/' className='text-gray-800'>
											Home
										</Link>
									</li>
									<li>
										<Link to='/about' className='text-gray-800'>
											About
										</Link>
									</li>
									<li>
										<Link to='/services' className='text-gray-800'>
											Services
										</Link>
									</li>
									<li>
										<Link to='/contact' className='text-gray-800'>
											Contact Us
										</Link>
									</li>
								</ul>
							</div>
						</div>

						<div className='mb-6 md:mb-0 w-full h-full flex flex-col flex-wrap justify-center items-center'>
							<div
								// to="tel:+918056035545"
								className='flex flex-row flex-wrap justify-center items-center pb-2'
							>
								<img
									src='https://img.icons8.com/ios-glyphs/30/000000/phone--v1.png'
									alt=''
								/>
								<a href='tel:+918122100724' className='pl-2 font-bold text-xl'>
									8122100724,
								</a>
								<a
									href='https://wa.me/message/7OXQGGBVE6NVN1'
									target={'_blank'}
									className='pl-2 font-bold text-xl' rel="noreferrer"
								>
									8056035545
								</a>
							</div>
							<div className='flex flex-row flex-wrap justify-center items-center pb-2 w-full'>
								{/* <img src="https://img.icons8.com/material-rounded/24/000000/new-post.png" alt="" /> */}
								<a
									href='mailto:andhaadhirehab@gmail.com'
									className='pl-2 font-bold text-xl'
								>
									andhaadhirehab@gmail.com
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='relative flex flex-row flex-wrap w-full justify-center items-center'>
					<a href='https://www.youtube.com/@andhaadhirehab.speechtherapy'>
						<img
							src='https://img.icons8.com/color/48/000000/youtube-play.png'
							alt=''
						/>
					</a>
					<a href='https://www.instagram.com/andhaadhirehab.speechtherapy/'>
						<img
							src='https://img.icons8.com/fluency/48/000000/instagram-new.png'
							alt=''
						/>
					</a>

					<a href='https://www.linkedin.com/company/andhaadhi-rehab/'>
						<img
							src='https://img.icons8.com/color/48/000000/linkedin-circled--v1.png'
							alt=''
						/>
					</a>
					<a href='https://www.facebook.com/people/Andhaadhi-Rehab-Speech-Therapy/100084872801231/?mibextid=ZbWKwL'>
						<img
							src='https://img.icons8.com/color/48/000000/facebook-new.png'
							alt=''
						/>
					</a>
				</div>
				<div className='relative text-center text-gray-700 p-4 z-10'>
					<b> © Copyright Andhaadhi Rehab 2022</b>
				</div>
			</footer>
		</>
	);
};

export default Footer;
