import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useGoogleSheets from "use-google-sheets";

export default function EventsMain() {
    const { data: events, loading } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsOptions: [{ id: "Events" }],
    });

    const [display, setDisplay] = useState(null);

    useEffect(() => {
        setDisplay(!loading ? events[0] && events[0].data[0] : {});
    }, [loading, events]);

    return (
        <section className="w-[90%] xl:w-3/4  mx-auto relative">
            <Link to="/events">
                <h1 className="font-bold text-2xl md:text-3xl text-[#739b24] text-left mb-5">
                    <span className="underline underline-offset-8">
                        Workshops
                    </span>
                </h1>
            </Link>
            {!events.length <= 0 ? (
                <>
                    {events[0] && events[0].data.length > 0 && (
                        <>
                            {/* <Link
                                to="/events"
                                className="text-md md:text-lg font-medium text-right text-blue-500"
                            >
                                View more
                            </Link> */}
                            <div className="flex-col    lg:flex-row    mt-0 sm:mt-5 mb-5 flex flex-wrap justify-between items-center mx-auto ">
                                {!loading &&
                                    events[0].data
                                        .slice(0, 4)
                                        .map((event, index) => {
                                            return (
                                                <EventBox
                                                    key={index}
                                                    event={event}
                                                    color={
                                                        index % 2 === 0
                                                            ? "#5EC6A6"
                                                            : "#246097"
                                                    }
                                                />
                                            );
                                        })}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="center w-full flex flex-col justify-center items-center py-10">
                    <h1 className="max-w-lg text-3xl font-semibold leading-normal">
                        No events right now
                    </h1>
                    <h3 className="max-w-lg text-3xl font-semibold leading-normal">
                        Stay tuned!!
                    </h3>
                </div>
            )}
        </section>
    );
}

const EventBox = ({ color, event }) => {
    return (
        <>
            <div className="hover:scale-105 ease-in-out duration-300 bg-[#FAFAFA]  w-6/6 lg:w-[48%] mt-4 sm:mt-8 h-[200px] flex items-center overflow-hidden drop-shadow-md">
                <div
                    style={{ backgroundColor: color }}
                    className={`w-1/4 lg:h-[250px] sm:h-[220px] h-[200px]   rounded-tr-full rounded-br-full flex flex-col justify-center items-left`}
                >
                    <h1 className="text-white text-3xl sm:text-4xl font-light ml-[25%]">
                        {event.Date}
                    </h1>
                    <h1 className="text-white text-1xl sm:text-2xl mt-2 font-bold ml-[25%]">
                        {event.Month}
                    </h1>
                </div>
                <div className="w-3/4 h-full flex flex-col justify-items-start p-5">
                    <h1 className="ml-5 mb-3 text-md md:text-lg font-semibold ">
                        {event.Title}
                    </h1>
                    <h1 className="text-sm  sm:text-base  ml-5 w-5/6  antialiased font-normal">
                        {event.Description.slice(0, 120)}...
                        <a href="/events" className="ml-5 mb-3 text-blue-700 ">
                            Read More
                        </a>
                    </h1>
                </div>
            </div>
        </>
    );
};
