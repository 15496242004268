import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../components/navbar/Navbar";
import Pagination from "../components/pagination/pagination";
import useGoogleSheets from "use-google-sheets";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SliderTestimonials from "../components/Testimonials/swiper";
import herobg from "../images/home/Group2.png";
import Footer from "../components/footer/Footer";

const Testimonial = () => {
    const { data: testimonials, loading } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsOptions: [{ id: "Testimonials" }],
    });

    useEffect(() => {
        console.log(testimonials);
    }, []);
    return (
        <>
            <Helmet>
                <title>Testimonial</title>
            </Helmet>
            <Navbar className="z-50" page={"Testimonials"} />

            <section className="h-[260px] sm:h-[350px] md:h-screen flex items-center justify-center z-0 relative">
                <img
                    src={herobg}
                    alt=""
                    className="h-3/6 w-full md:h-4/6 absolute top-0 -z-50"
                />

                <div className="max-w-[400px] sm:max-w-[none] w-[90%] sm:w-3/6 h-3/4  z-10 border-r-8 border-t-8 border-white shadow-lg">
                    <SliderTestimonials className=""></SliderTestimonials>
                </div>
            </section>
            {testimonials[0] && console.log(testimonials[0].data)}

            <section className="w-full p-[10px] lg:p-[40px] bg-[#ffffff] min-h-screen">
                <div className="w-[90%] lg:w-[85%] xl:w-[80%] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 justify-items-center mx-auto">
                    {testimonials[0] &&
                        testimonials[0].data.slice(0, 5).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="w-[95%] lg:w-[95%] max-w-[400px] h-[300px] bg-[white] drop-shadow-md mb-[2rem] flex flex-col justify-center p-2"
                                >
                                    <iframe
                                        className="w-[100%] h-[85%] "
                                        src={item["Video URL"]}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    <div className="flex w-full p-1 items-center h-[15%]">
                                        <FontAwesomeIcon
                                            icon={faLink}
                                            className="p-1 text-[#424242]"
                                        />

                                        <Link
                                            to={item["Video URL"]}
                                            target="_blank"
                                            className="text-[#424242]"
                                        >
                                            Visit Youtube
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    <div
                        onClick={() =>
                            window.open(
                                "https://www.youtube.com/playlist?list=PL-WrWb6UiMoxtwVoVIfQDLhdWC3Lee5y7",
                                "_blank"
                            )
                        }
                        className="w-[95%] lg:w-[95%] max-w-[400px] h-[300px] bg-[white] drop-shadow-md mb-[2rem] flex flex-col justify-center cursor-pointer p-2"
                    >
                        <div className="h-[85%] relative">
                            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEtklEQVR4nO2cTWhcVRTH/8Zq0YLVLjQuhPpBKv1QJKJx5pz3oGmgC1GsdCOoxGLFVdq6cCH4DYIuNLUouhCtLvzYVKugqKWaggtLjbTV4mdtrBVt1Yo10OD85b15E8YibSbvztx7k/ODA2EIw5zze2/m3nfvPYBhGIZhGIZhGIZhGIZhGIZhGIZhGA7hAOZR0MMEygSrWMUdTHAPBY9Q8DQVL1PwNhU7qNhJxV4qvs1D8BMVv+UhGKeCedT/rr+uODj5/4o9xXuMULGVis1UbKTgYQrWUzDIKm5iFcIKLmUfzopaNntxRp5IggEq7qLgcQpeoWI7BV9S8edk0UINwe+5dMGHuTDBYxTcSUE/BZcwxRyEAhUXFlfvluIKnfBeQG27oIki1y0UbGCK7s4Xvg8LqHhmVhRcTxnHKdhEwXmdKb7gCgq+DyBxBhWC75hiaXuLn+IyCn72nqwGGoJfWcGi9hR/Mc6kYLf3JDX4+DyrlXsB2Q+O/+QYSaxzW/zVOJ2CAwEkxkjiBwJd7gQorgkgKUYVFVztTkA2a/SdkEYWgvXuBCie856QRhfPuxMg+CiAhBhZjLgUYGN/bVGA4LC7p5f+ryZGGX1YUF5Aisu9J6KRRoKrygsQrPCaxKfvk7ctiVXAKhcCBr0mkTFxnHxjmFw5339RWwnBBhcC7vMuoMHRw+TwEJl2+S/u1GKjCwFPBSOgwb6d5N0V38U9dQheKy9A8VJwAjJqNXLb6+TNF4UsYJsLAVuDFNBg/C/yhQfJ5XNDFLDbhYAdQQto8OPX5P2rQxNwyIWAvVEIaBDSsDVbMwdOKytgf1QCQhu29uLscgLqm6HiEhDSsLXsbonsoVK0AkIYtpbdN0TF0egF+By2plhY9g4YnxECfA1by25ToeKfGSWg08PWKq40Af/H2Ffkvde3X0CCZfYV5HfmfHHZO2Dm/Ai/u5m8sbuzn/86nF/2DrBhqJYQMIB5s3ciduQQ+cRanxOxWukdcnE/ijjH3+eux7FSxS8E7InuYdyti30XvhEHXQgYiULAWIeGla3FaHkBgjeDFjAe8IKM4gMXAl4MUkDN07CylRC86kLAk8EJ2BfNovymmbUt5Yj3YWWrAh4oL0Bxu3cBE8EMK1sVMOjiDuj3msQn75C39Pgv5vQE9JcXUMEi74lopCHoKS/AtqdzmgJqpRfkJyXYAQ16mQVPCsi6nfi+nTWycLEtsUmAHdLTlgU8606AHVOl72OqdlBbWxbQ604A0JUfv/f9varRxH6nrQpyCfY1xCkLSDDktPi5gBRzKNgVwNXFwGM0653nXEAuIWvIZ3MCnqT4vziZ/Z5UQoqlRUtI31caA4tvWMWStha/ScK5ef/NrGGd/8TpObIaDHMF5nek+CeI6C4aoNbbVs4GIdLUtlKxjtfiAoRC/kOdYiEVy6lYQ8Wj+SlLwXv57grfm7x0SgX+g4IvTmjcujbvGBBa49bpwJWYW0iqMMEN+YJPgqFs9Shf/szWoOtX1/ai7fBnTa2LDzS1KD7WVLi/m1oajzW1Lh7N30PwMRVvFa2Lh6l46D+ti7M2yllHSFdPLw3DMAzDMAzDMAzDMAzDMAzDMAzDQJ1/AVPQr4Mf/K4RAAAAAElFTkSuQmCC" />
                            </div>
                            <img
                                className="w-[100%]  h-full"
                                src={require("../images/testimonials/playlist.png")}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></img>
                        </div>
                        <div className="flex w-full p-1 items-center h-[15%]">
                            <FontAwesomeIcon
                                icon={faLink}
                                className="p-1 text-[#424242]"
                            />
                            Visit Playlist
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Testimonial;
