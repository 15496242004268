import React from "react";
import ContactComponent from "../components/Contact/ContactComponent";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <div >
        <Helmet>
				<title>Contact</title>
			</Helmet>
            <Navbar page={"Contact"} />
            <ContactComponent />
            <Footer />
        </div>
    );
};

export default Contact;
