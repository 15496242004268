import {Link} from "react-router-dom";

export const DoctorsCard = ({doctor}) => {
	var imgDrive =doctor.Image.split("/")[5]
	
	return (
		<div className="relative flex flex-col justify-end items-end lg:h-[450px] m-3 p-2  sm:m-1 h-[30rem]">
			<div className="absolute w-[150px] h-[150px] top-0 left-5 rounded-full border-4 border-indigo-500/50 overflow-hidden z-10">
				<img
					className="w-full h-full relative object-cover"
					src={`https://drive.google.com/uc?export=view&id=${imgDrive}`}
					alt={doctor.Name}
				/>
			</div>
			<div className="relative lg:h-[350px] h-[25rem] flex flex-row flex-wrap justify-start items-end border-2 shadow-md p-5 pt-[80px] bg-white px-8 sm:w-[300px]">
				<Link
					to={"/doctor/" + doctor.Name}
					className="relative top-0 right-0 bg-orange-400 text-white rounded-xl px-2 py-1 m-2 lg:absolute"
				>
					Book a slot
				</Link>
				<div className="relative w-3/4 m-0 p-0 flex flex-row flex-wrap justify-between items-center">
					<h1 className="font-bold text-xl">{doctor.Name}</h1>
					<p className="text-blue-900 font-semibold ">
						{doctor.Experience} years
					</p>
				</div>
				<div>
					<h3 className="text-lg">{doctor.Therapist}</h3>
					<p className="text-left text-[1rem]">
						{doctor.Description.slice(0, 110)}{" "}
						{doctor.Description.length > 110 && "..."}
					</p>
				</div>
				<div className="relative w-full flex justify-end">
					<Link
						to={"/doctor/" + doctor.Name}
						className="pr-5 text-blue-600"
					>
						View more
					</Link>
				</div>
			</div>
		</div>
	);
};
