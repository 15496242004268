import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./css/swiper.css";

// images
import testimonial1 from "../../images/testimonials/Testimonial-1.jpg";
import testimonial2 from "../../images/testimonials/Testimonial-2.jpg";
import testimonial3 from "../../images/testimonials/Testimonial-3.jpg";
import testimonial4 from "../../images/testimonials/Testimonial-4.png";
import testimonial5 from "../../images/testimonials/Testimonial-5.png";

// import required modules
import {Navigation, Pagination, Mousewheel, Keyboard} from "swiper";

export default function SliderTestimonials() {
	return (
		<>
			<Swiper
				cssMode={true}
				navigation={true}
				pagination={true}
				mousewheel={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className="mySwiper"
			>
				<SwiperSlide>
					<img src={testimonial1} alt="testimonial1" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={testimonial2} alt="testimonial2" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={testimonial3} alt="testimonial3" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={testimonial4} alt="testimonial4" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={testimonial5} alt="testimonial5" />
				</SwiperSlide>
			</Swiper>
		</>
	);
}
