import { Disclosure } from '@headlessui/react';
// import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import { Link } from 'react-router-dom';
import logo from '../../images/Andhaadhi-Logo.png';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Nav({ page }) {
	const navigation = [
		{ name: 'Home', href: '/' },
		{ name: 'About', href: '/about' },
		{ name: 'Services', href: '/services' },
		{ name: 'Testimonials', href: '/testimonials' },
		{ name: 'Workshops', href: '/events' },
		{ name: 'Contact', href: '/contact' }
	];

	return (
		<Disclosure as='nav' className='z-50'>
			{({ open }) => (
				<div className='w-full bg-white'>
					<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 lg:bg-[#ffffff] w-d'>
						<div className='relative flex h-20 items-center justify-center'>
							<div className='absolute inset-y-0 right-10 flex items-center sm:hidden'>
								{/* Mobile menu button*/}
								<Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
									<span className='sr-only'>Open main menu</span>
									{open ? (
										// <XMarkIcon
										// 	className="block h-6 w-6"
										// 	aria-hidden="true"
										// />
										<img src='https://img.icons8.com/ios-glyphs/24/null/macos-close.png' />
									) : (
										// <Bars3Icon
										// 	className="block h-6 w-6"
										// 	aria-hidden="true"
										// />
										// "Open"
										<img src='https://img.icons8.com/material-outlined/24/null/menu--v1.png' />
									)}
								</Disclosure.Button>
							</div>
							<div className='flex flex-1 items-center justify-start sm:items-stretch sm:justify-between'>
								<div className='flex flex-shrink-0 items-center'>
									<Link to='/'>
										<img
											className=' absolute w-[6rem] md:w-[8rem] top-3 left-0'
											src={logo}
											alt=''
										/>
									</Link>
									{/* <img
                                        className="hidden h-8 lg:block rounded"
                                        src={logo}
                                        alt="Your Company"
                                    /> */}
								</div>
								<div className='hidden sm:ml-6 sm:block w-full'>
									<div className='flex space-x-4 justify-center'>
										{navigation.map((item) => {
											return (
												<a
													key={item.name}
													href={item.href}
													className={classNames(
														item.name === page
															? ' border-b-gray-900 border-b-2'
															: 'text-black hover:border-b-gray-900 border-b-2',
														'px-3 py-2 rounded-md text-sm font-medium text-lg'
													)}
													aria-current={item.name === page ? 'page' : undefined}
												>
													{item.name}
												</a>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>

					<Disclosure.Panel className='sm:hidden'>
						<div className='space-y-1 px-2 pt-2 pb-3'>
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as='a'
									href={item.href}
									className={classNames(
										item.name === page
											? 'bg-gray-900 text-white'
											: 'lg:text-gray-300 text-black hover:bg-gray-700 hover:text-white',
										'block px-3 py-2 rounded-md text-base font-medium'
									)}
									aria-current={item.name === page ? 'page' : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	);
}
