import React, { useState, useEffect } from "react";
import servicesJson from "../../config/services.js";
import ServicesCard from "./CardComponent";
import useGoogleSheets from "use-google-sheets";
const CardServicesComponent = () => {
    const { data: services, loading } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsOptions: [{ id: "Services" }],
    });
    // const [services, setServices] = useState(servicesJson);

    // const PAGE_SIZE = 4; // or whatever you like

    // const [index, setIndex] = useState(0);

    // const [visibleData, setVisibleData] = useState([]);
    // var i = 0;
    // useEffect(() => {
    //     const numberOfItems = PAGE_SIZE * (index + 1);

    //     const newArray = [];

    //     for (i = 0; i < services[0].data.length; i++) {
    //         if (i < numberOfItems) newArray.push(services[0].data[i]);
    //     }
       
    //     setVisibleData(newArray);
    // }, [index]);
    return (
        <>
            <section className="relative min-h-screen w-full flex flex-col px-20">
                {/* {JSON.stringify(services.map((service) => service.name))} */}
                <h1 className="left m-3 font-extrabold text-xl uppercase text-[#739b24]">Department of Speech and Language</h1>
                <div className="flex flex-wrap w-full">
                    {!loading && services[0].data.slice(0,7).map((service) => {
                        return (
                            <ServicesCard
                                key={service}
                                name={service.name}
                                description={service.description}
                                image={service.image}
                                link={service.link}
                            />
                        );
                    })}
                </div>
                <h1 className="left m-3 font-extrabold text-xl uppercase text-[#739b24]">Department of occupational therapy</h1>
                <div className="flex flex-wrap w-full">
                    {!loading && services[0].data.slice(7,11).map((service) => {
                        return (
                            <ServicesCard
                                key={service}
                                name={service.name}
                                description={service.description}
                                image={service.image}
                                link={service.link}
                            />
                        );
                    })}
                </div>
                <h1 className="left m-3 font-extrabold text-xl uppercase text-[#739b24]">Department of Special Education</h1>
                <div className="flex flex-wrap w-full">
                    {!loading && services[0].data.slice(11,14).map((service) => {
                        return (
                            <ServicesCard
                                key={service}
                                name={service.name}
                                description={service.description}
                                image={service.image}
                                link={service.link}
                            />
                        );
                    })}
                </div>
                <h1 className="left m-3 font-extrabold text-xl uppercase text-[#739b24]">Department of Mental health</h1>
                <div className="flex flex-wrap w-full">
                    {!loading && services[0].data.slice(14,services[0].data.length).map((service) => {
                        return (
                            <ServicesCard
                                key={service}
                                name={service.name}
                                description={service.description}
                                image={service.image}
                                link={service.link}
                            />
                        );
                    })}
                </div>
                {/* {visibleData.length != services.length ? (
					<button
						onClick={() => setIndex(index + 1)}
						className='p-3 bg-[#0d7fbc] text-[#ffffff] m-3 rounded-md'
					>
						{' '}
						Load More{' '}
					</button>
				) : null} */}
            </section>
        </>
    );
};

export default CardServicesComponent;
