import React from 'react';

import HeroImg from '../../images/doctor/hero-img.svg';

const Hero = ({ doctorDetails }) => {
	var imgDrive = doctorDetails.Image
		? doctorDetails.Image.split('/')[5]
		: 'link';
	return (
		<>
			<div className='h-[80vh] w-full relative'>
				<img
					src={HeroImg}
					alt='heroRight'
					className='w-full h-full object-cover '
				/>
				<div className='absolute w-52 h-52  md:w-64 md:h-64 top-1/2 left-1/2 -translate-x-1/2 -translate-y-[13rem] z-10 lg:-translate-y-1/2'>
					<img
						src={`https://drive.google.com/uc?export=view&id=${imgDrive}`}
						alt='Profile'
						className='absolute z-10 rounded-full w-full h-full object-cover'
					/>
					<div className='w-full h-full bg-slate-300 translate-x-3 absolute rounded-full'></div>
				</div>
				<div className='absolute top-1/2 md:translate-y-1/2 translate-y-1/2 left-1/2 -translate-x-1/2 lg:-translate-x-1/2 lg:left-1/4 lg:-translate-y-1/2'>
					<h1 className='font-bold text-4xl md:text-4xl text-white text-center lg:text-left'>
						Hi there!
					</h1>
					<h1 className='font-bold text-4xl md:text-4xl text-white text-center lg:text-left'>
						I am
					</h1>
					<h1 className='font-bold text-4xl md:text-4xl text-white text-center lg:text-left'>
						{doctorDetails && doctorDetails.Name}
					</h1>
				</div>
			</div>
			<div className='w-[75%] mx-auto py-10'>
				<p className='text-[1.1rem]'>
					{doctorDetails && doctorDetails.Description}
				</p>
			</div>
		</>
	);
};

export default Hero;
