import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useGoogleSheets from 'use-google-sheets';

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import Hero from '../components/doctor/Hero';
import CheckUpType from '../components/doctor/CheckUpType';

const Doctor = () => {
	const { name } = useParams();
	const [doctor, setDoctor] = useState(null);
	const { data: doctors, loading } = useGoogleSheets({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
		sheetsOptions: [{ id: 'Doctors' }]
	});
	useEffect(() => {
		setDoctor(
			// eslint-disable-next-line
			!loading ? doctors[0].data.filter((doctor) => doctor.Name == name)[0] : {}
		);
	}, [loading, doctors, name]);

	const [selectedCheckUp, setSelectedCheckUp] = useState('');

	useEffect(() => {
	  console.log(selectedCheckUp);
	
	}, [selectedCheckUp])
	

	// const doctorDetails = {
	// 	id: 'gddgdfg',
	// 	first_name: 'Lohith',
	// 	last_name: 'MBBS',
	// 	email: 'srklohith05@gmail.com',
	// 	phone_number: 781005277,
	// 	image:
	// 		'https://www.healthiummedtech.com/codefiles/NewTheme/img/team-imgs/ramesh-subrahmanian.jpg',
	// 	years_of_experience: 3,
	// 	Specialization: 'Cardiologist',
	// 	description: 'Na doctor la illa summa potan',
	// 	category: 'Masters',
	// 	appointments: []
	// };

	if(!doctor) return

	return (
		<div >
			<Helmet>
				<title>Team</title>
			</Helmet>
			<Navbar />
			<Hero doctorDetails={ doctor} />
			<CheckUpType
				selectedCheckUp={selectedCheckUp}
				setSelectedCheckUp={setSelectedCheckUp}
				doctorDetails={ doctor}
			/>
			<Footer />
		</div>
	);
};

export default Doctor;
